// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");

function useReducer(initialState, reducer) {
  var match = React.useReducer((function (fullState, action) {
          var state = reducer(fullState.state, action);
          if (typeof state !== "object") {
            return fullState;
          } else if (state.TAG === "Update") {
            return {
                    state: state._0,
                    sideEffects: fullState.sideEffects
                  };
          } else {
            return {
                    state: state._0,
                    sideEffects: {
                      contents: Belt_Array.concat(fullState.sideEffects.contents, [state._1])
                    }
                  };
          }
        }), {
        state: initialState,
        sideEffects: {
          contents: []
        }
      });
  var dispatch = match[1];
  var match$1 = match[0];
  var sideEffects = match$1.sideEffects;
  var state = match$1.state;
  React.useEffect((function () {
          if (sideEffects.contents.length !== 0) {
            Belt_Array.forEach(sideEffects.contents, (function (fn) {
                    fn({
                          state: state,
                          dispatch: dispatch
                        });
                  }));
            sideEffects.contents = [];
          }
          
        }), [sideEffects.contents]);
  return [
          state,
          dispatch
        ];
}

exports.useReducer = useReducer;
/* react Not a pure module */
